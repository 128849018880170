<template>
	<div class="market-preview">
		<div class="breadcrumb">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>研究报告</el-breadcrumb-item>
				<el-breadcrumb-item
					:to="{
						path: type == 1 ? '/research/new-market' : '/research/subdivide-market',
					}"
				>
					{{ type == 1 ? "市场简报" : "专题报告"}}
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="top-box">
			<img @dragstart.prevent class="left-img" :src="detailData.frontCoverImg" alt="" />
			<div class="right-box">
				<el-tooltip effect="light" :content="detailData.reportTitle" placement="bottom-start">
					<div class="right-name text-ellipsis">
						{{ detailData.reportTitle }}
					</div>
				</el-tooltip>
				<div class="num-box">
					<div class="collect" @click="toCollect">
						<span
							class="iconfont icon-shoucang"
							:style="{
								color: detailData.isCollection == 1 ? '#fca82f' : '#cbd1db',
							}"
						></span>
						<span>{{ detailData.isCollection == 1 ? "已" : "" }}收藏</span>
					</div>
					<div class="num">
						<span class="iconfont icon-liulan"></span>
						<span>浏览量{{ detailData.showReadingQty }}+</span>
					</div>
					<div class="num">
						<span class="iconfont icon-xiazai2"></span>
						<span>下载{{ detailData.showDownloadQty }}+</span>
					</div>
				</div>
				<div class="item-box">
					<div class="item-name">来源：</div>
					<div class="item-main">{{ detailData.reportSource || "-" }}</div>
				</div>
				<div class="item-box">
					<div class="item-name">类型：</div>
					<div class="item-main">{{ detailData.categoryName || "-" }}</div>
				</div>
				<div class="item-box">
					<div class="item-name">咨询电话：</div>
					<div class="item-main">{{servicePhone}}</div>
				</div>
				<div class="item-box">
					<div class="item-name">购买须知：</div>
					<div class="item-main">您将购买的商品为虚拟内容服务，购买后不支持退款、转让、退换，请仔细斟酌确认！</div>
				</div>
				<div class="item-box">
					<div class="item-name">特别声明：</div>
					<!-- <div class="item-main">
                        该内容为本公司原创，拥有唯一著作权，未经本公司事先允许，任何组织和个人不得以任何方式进行转载、复制。
                    </div> -->
					<div class="item-main">
						{{ detailData.lawStatement }}
					</div>
				</div>
				<div class="buy-box">
					<div class="read-box">
						<div class="read-icon"></div>
						<div class="read-name">在线阅读：</div>
						<div class="read-btn" @click="toPay(1)" v-if="detailData.readPrice != 0">￥{{ detailData.readPrice }}/期</div>
						<div v-if="detailData.readPrice == 0">
							<span style="color: #23ab82">免费</span>
						</div>
					</div>
					<div class="btn-box">
						<span class="iconfont icon-download"></span>
						<div class="btn-name">下载阅读：</div>
						<div class="download-btn" @click="toPay(2)">￥{{ detailData.downloadPrice }}/期</div>
					</div>
					<div class="btn-box">
						<span class="iconfont icon-member"></span>
						<div class="btn-name">下载阅读：</div>
						<div class="member-btn" @click="toMember">开通会员获取更多免费阅读次数</div>
					</div>
				</div>
			</div>
		</div>
		<div class="custom-img" v-show="banners[0].img != ''">
			<img @dragstart.prevent class="custom-img" :src="banners[0].img" alt="" :style="{cursor: banners[0].linkUrl ? 'pointer' : ''}" @click="clickBanner(banners[0].linkUrl)" />
		</div>
		<div class="file-box">
			<div class="file-name-box">
				<div class="file-img"></div>
				<el-tooltip effect="light" :content="detailData.reportTitle" placement="bottom-start">
					<div class="file-name text-ellipsis">
						{{ detailData.reportTitle }}
					</div>
				</el-tooltip>
			</div>
			<div class="file-content">
				<pdf v-for="item in numPages" :key="item" :src="detailData.trialReadingFile" :page="item"></pdf>
			</div>
			<div class="masking-box">
				<div class="masking"></div>
				<div class="text-box" @click="gotoMember">
					<span>剩余{{ detailData.pageTotal - detailData.trialReadingPage }}页未读，</span>
					<span class="member">开通会员获取更多免费阅读次数</span>
				</div>
			</div>
		</div>
		<div class="small-title-box" v-show="recommendList.length != 0">
			<div class="small-title">推荐阅读</div>
		</div>
		<div class="recommend-box" v-show="recommendList.length != 0">
			<el-row :gutter="20">
				<el-col :span="12" v-for="(item, i) in recommendList" :key="i">
					<div class="grid-content" @click="toDetail(item.reportId)">
						<img @dragstart.prevent :src="item.frontCoverImg" alt="" />
						<div class="right-box">
							<el-tooltip effect="light" :content="item.reportTitle" placement="bottom-start">
								<div class="title text-ellipsis-two">
									{{ item.reportTitle }}
								</div>
							</el-tooltip>
							<div class="content text-ellipsis-four">
								{{ item.reportDesc }}
							</div>
							<div class="price-box">
								<div v-if="item.readPrice != 0">
									订阅价格：
									<span class="unit">￥</span>
									<span class="price">{{ item.readPrice }}</span>
									/期
								</div>
								<div v-if="item.readPrice == 0">
									订阅价格：
									<span class="unit"></span>
									<span style="color: #23ab82">免费</span>
								</div>
								<div class="down-price" v-if="item.downloadPrice != 0">
									下载价格：
									<span class="unit">￥</span>
									<span class="price">{{ item.downloadPrice }}</span>
									/期
								</div>
								<div class="down-price" v-if="item.downloadPrice == 0">
									下载价格：
									<span class="unit"></span>
									<span style="color: #23ab82">免费</span>
								</div>
							</div>
							<!-- <div class="member">
								会员价格：
								<span>免费</span>
							</div> -->
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<pay-modal ref="payModal"></pay-modal>
		<ToPayOrMember ref="toPayOrMember"></ToPayOrMember>
	</div>
</template>

<script>
import PayModal from "@/components/payModal";
import pdf from "vue-pdf";
import ToPayOrMember from "@/components/ToPayOrMember";
import { mapGetters } from "vuex";
export default {
	components: {
		PayModal,
		pdf,
		ToPayOrMember
	},
	data() {
		return {
			type: "", // 研究报告类型
			reportId: "", //报告id
			detailData: {},
			recommendList: [],
			banners: [
				{
					linkUrl: "",
					img: "",
				},
			],
			numPages: "",
			orderData:{}
		};
	},
	computed:{
    ...mapGetters(['servicePhone'])
  },
	watch: {
		$route(to, from) {
			if (to.query.id != from.query.id) {
				let type = to.query.type;
				this.type = type;
				let reportId = to.query.id;
				this.reportId = reportId;
				this.getReportInfo(reportId);
				this.getBannerList(type);
				this.getReportRecommended(type, reportId);
			}
		},
	},
	mounted() {
        console.log(this.$route.query,'eww');
		let type = this.$route.query.type;
		this.type = type;
		let reportId = this.$route.query.id;
		this.reportId = reportId;
		this.getReportInfo(reportId);
		this.getBannerList(type);
		this.getReportRecommended(type, reportId);
	},
	methods: {
		// 点击收藏（取消/收藏）
		toCollect() {
			let {detailData} = this;
			if (detailData.isCollection == 0) {
				// 收藏
				this.$api.personalCenter
					.collection({
						relationId: this.reportId,
						collectionType: 60611001,
					})
					.then(() => {
						this.$message.success("收藏成功");
						this.detailData.isCollection = 1;
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else {
				// 取消收藏
				this.$api.personalCenter
					.cancel({
						relationId: this.reportId,
						collectionType: 60611001,
					})
					.then(() => {
						this.$message.success("取消收藏成功");
						this.detailData.isCollection = 0;
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			}
		},
		// 获取报告数据
		getReportInfo(reportId) {
			this.$api.research
				.getReportInfo({
					reportId,
				})
				.then(res => {
					let src = pdf.createLoadingTask(res.data.trialReadingFile);
					src.promise.then(pdf => {
						this.numPages = pdf.numPages;
					});
					this.detailData = res.data;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 研报推荐阅读
		getReportRecommended(type, reportId) {
			this.$api.research
				.getReportRecommended({
					reportId,
					platform: 1,
					childModuleCode: type == 1 ? "10011001" : "10011002",
				})
				.then(res => {
					this.recommendList = res.data;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 获取轮播
		getBannerList(type) {
			this.$api.home
				.getBannerList({
					code: type == 1 ? "10011001" : "10011002",
					platform: 1,
				})
				.then(res => {
					if (res.data.length == 0) {
						return;
					}
					this.banners = res.data;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 点击轮播图
		clickBanner(url) {
			if (url) {
				window.open(url, "_blank");
			}
		},
		// 点击剩余页
		gotoMember() {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		// 点击列表进入详情
		toDetail(reportId) {
			// 判断阅读，下载及会员情况
			let {type} = this;
			this.$api.research
				.getReadAndDownload({
					reportId,
				})
				.then(res => {
					let data = res.data;
					if (data.read == "10011001") {
						// 有阅读权限
						let url = this.$router.resolve({
							path: `/research/market-detail?type=${type}&id=${reportId}`,
						});
						window.open(url.href, "_blank");
					} else {
						// 没有阅读权限
						let url = this.$router.resolve({
							path: `/research/market-preview?type=${type}&id=${reportId}`,
						});
						window.open(url.href, "_blank");
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 点击阅读或下载
		toPay(type) {
				this.$api.research.getPayDetail({reportId: this.reportId, readOrDownLoad:type==1?"60491001":"60491002"})
				.then(res => {
					this.$refs.payModal.orderData = {...res.data,reportPay:true};
					this.$refs.payModal.open = true;
				})
				.catch(error => {
					this.$message.error(error?.msg);
				});
			// type == 1 （阅读支付）type == 2 （下载支付）
			this.$refs.payModal.init(type, this.detailData);
		},
		// 进入会员页
		toMember() {
			let url = this.$router.resolve({
				path: `/member`,
			});
			window.open(url.href, "_blank");
		},
	},
};
</script>
<style lang="less">
@import "./index.less";
</style>
